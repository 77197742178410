<template>
    <li><a :href="url" target="_blank">{{ title }}</a></li>
</template>

<script>
    import { getFieldValues } from '@/utils/helpers';

    export default {
        name: "HilfreicheLinks",
        props: {
            link: {
                type: Object,
                required: true
            },
        },
        computed: {
            title(){
                var value = getFieldValues(this.link,'title');
                return value!==null?value:null;
            },
            url(){
                var value = getFieldValues(this.link,'link-url');
                return value!==null?value:null;
            },
        },
        methods:{
         getFieldValues, 
     }
 }
</script>